import React from 'react';
import { CurrentCustomCorporatePageBySlugQuery } from '@/apollo/generated';
import GovernancePageSection from '@/components/governance/section';

interface Props {
  currentGovernancePage: NonNullable<
    CurrentCustomCorporatePageBySlugQuery['currentCustomCorporatePageBySlug']
  >;
}

const GovernancePageContent: React.ComponentType<Props> = ({
  currentGovernancePage,
}) => {
  return (
    <div className="space-y-8 md:space-y-12">
      {currentGovernancePage.sections.map((section) => (
        <GovernancePageSection
          key={`governance-page-section-${section.id}`}
          governancePageSection={section}
        />
      ))}
    </div>
  );
};

export default GovernancePageContent;
