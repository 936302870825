import React from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react-v2/24/solid';
import Link from 'next/link';
import { Typography } from '@leaf/ui';
import { CurrentCustomCorporatePageBySlugQuery } from '@/apollo/generated';

interface Props {
  governancePageSectionAttachment: NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<
            CurrentCustomCorporatePageBySlugQuery['currentCustomCorporatePageBySlug']
          >['sections']
        >[0]
      >['attachments']
    >[0]
  >;
}

const GovernancePageSectionAttachment: React.ComponentType<Props> = ({
  governancePageSectionAttachment,
}) => {
  return (
    <>
      {governancePageSectionAttachment.fileUrl && (
        <Link
          className="flex max-w-xs items-center gap-4 sm:max-w-xl"
          href={governancePageSectionAttachment.fileUrl}
          tabIndex={-1}
          target="_blank"
        >
          <div className="h-5 w-5">
            <ArrowDownTrayIcon className="h-5 w-5 text-company-primary" />
          </div>
          <Typography
            className="truncate text-company-primary"
            variant="button"
          >
            {governancePageSectionAttachment.heading}
          </Typography>
        </Link>
      )}
    </>
  );
};

export default GovernancePageSectionAttachment;
