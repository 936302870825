import React from 'react';

const GovernancePageLoadingScreen: React.ComponentType = () => {
  return (
    <div className="h-128 space-y-8 rounded-lg border ">
      <div className="m-auto max-w-screen-xl space-y-8 p-8">
        <div className="h-32 w-80 animate-pulse rounded-lg bg-hubs-background-accent" />

        <div className="space-y-4">
          <div className="h-8 w-1/2 animate-pulse rounded-lg bg-hubs-background-accent" />
          <div className="h-8 w-1/2 animate-pulse rounded-lg bg-hubs-background-accent" />
          <div className="h-8 w-3/4 animate-pulse rounded-lg bg-hubs-background-accent" />
        </div>

        <div className="space-y-4">
          <div className="h-8 w-1/2 animate-pulse rounded-lg bg-hubs-background-accent" />
          <div className="h-8 w-3/4 animate-pulse rounded-lg bg-hubs-background-accent" />
          <div className="h-8 w-3/4 animate-pulse rounded-lg bg-hubs-background-accent" />
        </div>
      </div>
    </div>
  );
};

export default GovernancePageLoadingScreen;
