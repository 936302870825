import React from 'react';
import { SuperRichTextEditor, Typography } from '@leaf/ui';
import { CurrentCustomCorporatePageBySlugQuery } from '@/apollo/generated';
import GovernancePageSectionAttachment from '@/components/governance/attachment';

interface Props {
  governancePageSection: NonNullable<
    NonNullable<
      NonNullable<
        CurrentCustomCorporatePageBySlugQuery['currentCustomCorporatePageBySlug']
      >['sections']
    >[0]
  >;
}

const GovernancePageSection: React.ComponentType<Props> = ({
  governancePageSection,
}) => {
  return (
    <div className="space-y-6 rounded-lg border p-6">
      <Typography className="text-company-primary" variant="heading-4">
        {governancePageSection.heading}
      </Typography>

      <div className="max-w-[700px]">
        <SuperRichTextEditor
          readOnly
          content={
            governancePageSection.description
              ? governancePageSection.description
              : undefined
          }
        />
      </div>
      {governancePageSection.attachments.length > 0 && (
        <div className="space-y-4">
          {governancePageSection.attachments.map((attachment) => (
            <GovernancePageSectionAttachment
              key={`governance-page-section-${governancePageSection.id}-attachment-${attachment.id}`}
              governancePageSectionAttachment={attachment}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default GovernancePageSection;
